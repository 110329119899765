.content-block {
  margin-bottom: 16px;
  margin-top: 16px;
}
h2 {
  margin: 0;
  margin-top: 16px;
  text-align: center;
}
h4 {
  margin: 0;
  text-align: center;
}
.tournament {
  color: #912626;
}
.statBox {
  margin: 4px 8px;
}
.statBox p {
  font-size: 12px;
  text-align: left;
  margin: 4px 0;
}
.gameButtonContainer{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items:center;
}
.gamebutton{
  background-color: var(--main-color);
  color: white;
  padding: 8px;
  border-radius: 4px;
  border: none;
  margin: 8px 16px;
  flex: 1;
  width: 200px;
}
.gamebutton h5{
  white-space: nowrap;
}
.gamebutton p{
  margin: 0;
}
.gamebutton.selected {
  border: 4px dashed #c1c1c1;
}

.gameKeyContainer{
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
}
.gameKey{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 16px;
}
.keybox{
  width:20px;
  height:20px;
  margin-right: 8px;
}

.home{
  background-color: var(--main-color);
}


.away{
  background-color: var(--silver);
  color: var(--main-color);
}


.tournament{
  background-color: var(--sec-color);
}
.selected{
  background-color: white;
  color: var(--main-color);
  border-color: var(--main-color);
}