table{
    margin: 8px;
}

td{
    text-align: center;
    min-width: 4ch;
}

.rankings tr:nth-child(even){
    background-color: lightgray;
}

td input {
    width: 4ch;
    text-align: center
}

.teamSize{
    display:flex;
    justify-content:center;
    align-items: center;
    margin: 4px 0;
}
.teamSize button{
    width: 24px;
    height: 24px;
    margin: 0 8px;
    background-color: var(--main-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.teamSize button:disabled{
    background-color: white;
    border: none;
}