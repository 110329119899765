.card .cardtitle{
    background-color: var(--main-color);
    color: white;
    justify-content: center;
    margin: 0;
}
.recordEntries{
    margin: 8px;
}
.recordEntry{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-evenly; */
}
.recordData{
    display: flex;
    align-items: center;
}
.recordAmount{
    margin: 0 16px;
    width: 100px;
}
.recordAmount h2{
    margin-top: 0;
}
.recordName{
    flex: 1;
}
.recordName h5{
    white-space:nowrap;
}
.recordPhoto{
    height: 100px;
    aspect-ratio: 1;
    border-radius: 50px;
    background-color: #ccc;
    margin: 16px;
    background-position: center;
    background-size: contain;
}
@media only screen and (max-width: 680px) {
    .recordAmount{
        margin: 0 10px;
        width: 64px;
    }
    .recordPhoto{
        height: 64px;
        border-radius: 32;
        margin: 10px;
    }
}
@media only screen and (max-width: 480px) {
    .recordAmount{
        margin: 0 8px;
        width: 50px;
    }
    .recordPhoto{
        height: 50px;
        border-radius: 25;
        margin: 8px;
    }
}
@media only screen and (max-width: 320px) {
    .recordAmount{
        margin: 0 4px;
        width: 30px;
    }
    .recordPhoto{
        height: 30px;
        border-radius: 15;
        margin: 4px;
    }
}