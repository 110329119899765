.award{
    display: flex;
    align-items: center;
}
.award h2{
    background-color: var(--main-color);
   color: white;
   width: 100%;
   margin: 0;
}
.award img{
    width: 80%;
    margin: 8px;
    border-radius: 8px;
}