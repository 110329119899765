.teamBox {
  margin: 16px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.teamBox table{
  border: 0px;
}
.teamBox table td{
  border: 0px;
}