.calwrapper {
	margin: 0 4px;
	/* font-family: initial !important; */
}
.calwrapper table {
	margin: 0;
}
.fc-scroller-harness {
	overflow: visible !important;
}
.fc-event {
	justify-content: stretch;
}
.fc .fc-button {
	padding: 4px;
	text-transform: capitalize;
	font-size: 0.75em;
}
.fc .fc-button-primary {
	background-color: var(--main-color);
}
.fc-h-event {
	border: 0px;
	background-color: var(--main-color);
}
.fc-scroller {
	height: auto !important;
	overflow: visible !important;
}
.calEvent {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	background-color: var(--main-color);
	color: white;
	padding: 4px;
	border-radius: 4px;
	width: 100%;
}
.calEvent p {
	font-size: 12px;
	line-height: 14px;
	width: 100%;
	word-wrap: break-word;
	display: block;
	overflow: hidden;
}
.rbc-month-row {
	overflow: visible;
}

/* Sizing */
@media only screen and (max-width: 600px) {
	.calEvent p {
		font-size: 11px;
		line-height: 12px;
	}
}
@media only screen and (max-width: 500px) {
	.calEvent p {
		font-size: 10px;
		line-height: 12px;
	}
}
@media only screen and (max-width: 400px) {
	.ampm {
		display: none;
	}
}
